import { createApp, h, VueConstructor  } from 'vue'

export default function RenderVueComponentAsString(component : VueConstructor, props : {}) {
    const tempApp = createApp({
        render() {
            return h(component, props);
        }
    });

    const tempElement = document.createElement('div');
    tempApp.mount(tempElement)

    const res = tempElement.innerHTML;
    tempApp.unmount();
    return res;
}